import React from "react"
import { Button, Box, useDisclosure } from "@chakra-ui/react"

import { FaRegListAlt } from "react-icons/fa"
import ModalData from "./ModalData"

const AddButton = ({ onSaveInputData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} leftIcon={<FaRegListAlt />}>
        Data
      </Button>

      <ModalData
        onSaveInputData={onSaveInputData}
        isOpen={isOpen}
        onClose={onClose}
      ></ModalData>
    </>
  )
}

export default AddButton
