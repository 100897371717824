import React from "react"
import { FaSyncAlt } from "react-icons/fa"
import autoBind from "auto-bind"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
} from "@chakra-ui/react"
import _ from "lodash"

class ModalData extends React.Component {
  constructor(props) {
    super(props)

    // check localstorage
    let initialData =
      _.toString(JSON.parse(localStorage.getItem("basePicker"))).replace(
        /,/gi,
        "\n"
      ) || ""

    this.state = {
      textAreaContent: initialData,
    }

    autoBind(this)
  }

  handleTextChange(e) {
    this.setState({
      textAreaContent: e.target.value,
    })
  }

  handleSave() {
    // ubah content menjadi array
    let arrContent = _.split(this.state.textAreaContent, "\n")
    let trimmedTexts = arrContent.map((x) => x.replace(/^\s+|\s+$/gm, ""))
    console.log(trimmedTexts)
    this.props.onSaveInputData(trimmedTexts)
    this.props.onClose()
  }

  onRefreshData() {
    this.setState({
      textAreaContent:
        _.toString(JSON.parse(localStorage.getItem("basePicker"))).replace(
          /,/gi,
          "\n"
        ) || "",
    })
  }
  render() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} onClose={this.props.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Data</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Textarea
                rows="10"
                value={this.state.textAreaContent}
                onChange={this.handleTextChange}
                placeholder="masukan data, satu data untuk tiap baris"
              />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="green" mr={3} onClick={this.onRefreshData}>
                <FaSyncAlt />
              </Button>
              <Button colorScheme="blue" onClick={this.handleSave}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
}

export default ModalData
