import autoBind from "auto-bind"
import React from "react"
import { SimpleGrid, Center, Box } from "@chakra-ui/react"
import AddButton from "./AddButton"
import Header from "./Header"
import ItemsToPick from "./ItemsToPick"
import PickButton from "./PickButton"
import Footer from "./Footer"
import _ from "lodash"

class App extends React.Component {
  constructor(props) {
    super(props)

    // dapatkan data inputan dari localstorage
    let localStorageData = JSON.parse(localStorage.getItem("basePicker")) || []

    // state
    this.state = {
      base: localStorageData,
      loading: false,
      result: "",
      waitingTime: "",
      pickingTime: 4000,
      textArea: "",
    }

    // binding
    autoBind(this)
  }

  onSaveInputData(data) {
    this.setState({
      base: data,
    })
    console.log("saved", data)
    localStorage.setItem("basePicker", JSON.stringify(data))
  }

  pickedItem() {
    // algoritma untuk memilih secara random dari array
    let choiceIndex = Math.floor(Math.random() * this.state.base.length)
    return choiceIndex
  }

  onItemChange(oldItem, newItem) {
    // temukan index dari item dengan judul tersebut
    let idx = _.findIndex(
      this.state.base,
      (e) => {
        return e === oldItem
      },
      0
    )

    // buat base baru dan ganti value pada index tadi
    let newBase = this.state.base
    newBase[idx] = newItem

    // set state baru dan simpan di localstorage
    this.setState({
      base: newBase,
    })
    localStorage.setItem("basePicker", JSON.stringify(newBase))
  }

  onItemDelete(item) {
    const idx = _.findIndex(
      this.state.base,
      (e) => {
        return e === item
      },
      0
    )

    // hapus item
    let newBase = this.state.base
    newBase.splice(idx, 1)

    // simpan di localstorage dan set states
    this.setState({
      base: newBase,
    })
    localStorage.setItem("basePicker", JSON.stringify(newBase))
  }

  onStartPicking() {
    // buat status menjadi loading, set result
    this.setState({
      loading: !this.state.loading,
      result: false,
      waitingTime: this.state.pickingTime,
    })

    // ganti result tiap 10 milisecond
    const kocok = setInterval((x) => {
      this.setState({
        result: this.pickedItem(),
        waitingTime: this.state.waitingTime - 100,
      })
    }, 100)

    setTimeout(() => {
      this.setState({
        loading: false,
      })
      clearInterval(kocok)
    }, this.state.pickingTime)
  }

  render() {
    return (
      <>
        <Header />
        <Box my="5">
          <Center>
            <SimpleGrid columns={2} spacing={4}>
              <AddButton
                allData={this.state}
                basePicker={this.state.base}
                onSaveInputData={this.onSaveInputData}
              />
              <PickButton
                onItemDelete={this.onItemDelete}
                loadingStatus={this.state.loading}
                onStartPicking={this.onStartPicking}
                data={this.state}
              />
            </SimpleGrid>
          </Center>
        </Box>
        <ItemsToPick
          onItemDelete={this.onItemDelete}
          onItemChange={this.onItemChange}
          base={this.state.base}
          data={this.state}
        />
        <Footer />
      </>
    )
  }
}

export default App
