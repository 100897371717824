import React from "react"
import { Text, Center, Box, Link } from "@chakra-ui/react"

const Footer = () => {
  return (
    <Box mt="10vh">
      <Center>
        <Text fontSize="xs">
          created by{" "}
          <Link
            color="blue.500"
            href="https://www.linkedin.com/in/agung-kurniawan-10807310a/"
            isExternal
          >
            Agung.K
          </Link>
        </Text>
      </Center>
    </Box>
  )
}

export default Footer
