import React from "react"
import { Center, Box, Text } from "@chakra-ui/react"

const Header = () => {
  return (
    <>
      <Center bg="blue.600" h="100px" color="white">
        <Box>
          <Text fontSize="4xl">Simple Random Picker</Text>
        </Box>
      </Center>
    </>
  )
}

export default Header
