import React, { useState } from "react"
import { Button, Box, Center, Text, useDisclosure } from "@chakra-ui/react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
} from "@chakra-ui/react"
import { FaTrash, FaSave } from "react-icons/fa"

import "./style.css"
const IndividualItem = ({ text, onItemChange, onItemDelete, data }) => {
  const [title, setTitle] = useState(text)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleChange = (e) => {
    setTitle(e.target.value)
  }

  return (
    <Box
      onClick={onOpen}
      className="itemBox"
      _hover={{
        background: "blue.400",
      }}
      borderRadius="lg"
      background={title === data.base[data.result] ? "blue.900" : "blue.600"}
      height="40px"
    >
      <Center>
        <Text fontSize="2xl" color="white">
          {title}
        </Text>
      </Center>

      {/* modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input onChange={handleChange} value={title} />
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={(x) => {
                onClose()
                onItemDelete(title)
              }}
              colorScheme="red"
              mr={3}
              leftIcon={<FaTrash />}
            >
              Delete
            </Button>
            <Button
              onClick={(x) => {
                onClose()
                onItemChange(text, title)
              }}
              colorScheme="blue"
              leftIcon={<FaSave />}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default IndividualItem
