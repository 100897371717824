import React from "react"
import { SimpleGrid } from "@chakra-ui/react"
import _ from "lodash"

import IndividualItem from "./IndividualItem"

const ItemsToPick = ({ base, data, onItemChange, onItemDelete }) => {
  // sortir sesuai abjad
  let new_base = _.sortBy(base)
  return (
    <>
      <SimpleGrid my="4" mx="5" columns={{ lg: 3, md: 2, sm: 1 }} spacing={2}>
        {new_base.map((x) => (
          <IndividualItem
            data={data}
            onItemDelete={onItemDelete}
            onItemChange={onItemChange}
            key={x}
            text={x}
          />
        ))}
      </SimpleGrid>
    </>
  )
}

export default ItemsToPick
