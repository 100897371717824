import React from "react"
import {
  Button,
  Box,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Image,
  Text,
  Center,
} from "@chakra-ui/react"
import { FaPlay, FaTrash } from "react-icons/fa"

const PickButton = ({ onStartPicking, loadingStatus, onItemDelete, data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const winnerHandler = () => {
    setTimeout(() => {
      onOpen()
    }, data.pickingTime)
  }

  return (
    <Box>
      <Button
        onClick={(x) => {
          onStartPicking()
          winnerHandler()
        }}
        colorScheme="blue"
        leftIcon={loadingStatus ? <Spinner /> : <FaPlay />}
        disabled={loadingStatus}
      >
        {loadingStatus
          ? `wait ... ${Math.round(data.waitingTime / 1000)}`
          : "Start"}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image src="./freddy.png" />
            <Center>
              <Text fontSize="3xl">🎉{data.base[data.result]}🎉</Text>
            </Center>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              leftIcon={<FaTrash />}
              mr={3}
              onClick={(x) => {
                onClose()
                onItemDelete(data.base[data.result])
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default PickButton
